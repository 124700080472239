const EnvironmentConfig = {
  ApiBaseUrl: process.env.VUE_APP_BASE_URL,
  Azure: {
    ADAuthority: process.env.VUE_APP_AD_AUTHORITY,
    Authority: process.env.VUE_APP_B2C_AUTHORITY,
    AuthorityMfa: process.env.VUE_APP_B2C_AUTHORITY_MFA ?? 0,
    ClientId: process.env.VUE_APP_B2C_CLIENT_ID,
    KnownAuthority: process.env.VUE_APP_B2C_KNOWN_AUTHORITY,
    RedirectUri: process.env.VUE_APP_B2C_REDIRECT_URI,
    TokenScope: process.env.VUE_APP_B2C_TOKEN_SCOPE,
    // Pii log document link(rather vague by MS): https://learn.microsoft.com/en-us/azure/active-directory/develop/msal-logging-js
    // Pii Logs example: Verbose - getAccountByHomeId: Returning signed-in accounts matching homeAccountId: xxxxxx-xxxxxx-xxxxxxxx
    PiiLogging: process.env.VUE_APP_PII_LOGGING ?? false,
    // Values can be 0 = error, 1 = warning, 2 = info, 3 = verbose
    LogLevel: process.env.VUE_APP_LOG_LEVEL ?? 0,
  },
  ChatWidget: {
    AppId: process.env.VUE_APP_CHAT_APP_ID,
    OrgId: process.env.VUE_APP_CHAT_ORG_ID,
    OrgUrl: process.env.VUE_APP_CHAT_ORG_URL,
  },
  FeatureToggles: {
    BrandShowLogo:
      process.env.VUE_APP_FEATURE_TOGGLE_SHOW_POWERED_BY_LOGO ?? true,
  },
  Analytics: {
    Google: {
      TrackingId: process.env.VUE_APP_GA_TRACKING_ID,
      Enabled: process.env.VUE_APP_GA_ENABLED,
    },
  },
  Locale: {
    Region: process.env.VUE_APP_REGION,
  },
  UiBaseUrl: process.env.VUE_APP_ROOT_URL,
  Version: process.env.VUE_APP_VERSION,
  FeatureFlag: {
    ConnectionString: process.env.VUE_APP_FEATURE_FLAG_CONNECTION_STRING,
    Environment: process.env.VUE_APP_FEATURE_FLAG_ENVIRONMENT,
  },
};

const validateEnvironmentConfig = (obj) => {
  try {
    Object.entries(obj).some((keyValue) => {
      const [key, value] = keyValue;
      if (typeof value === "object") {
        validateEnvironmentConfig(value);
      } else if (value === null || value === "" || value === undefined) {
        console.error(
          `EnvironmentConfig: ${key} has invalid value of ${value}`
        );
      }
    });
  } catch (err) {
    console.error("Failed to validate environment config", err);
  }
};

validateEnvironmentConfig(EnvironmentConfig);

export default EnvironmentConfig;
